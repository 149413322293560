export class HSHelpers {
  public static OpenHSChat(): boolean {
    var hs = (window as any).HubSpotConversations
    // https://legacydocs.hubspot.com/docs/methods/conversations_api/hubspot-conversations-javascript-api
    if (hs !== undefined && hs.widget !== undefined && hs.widget.status().loaded) {
      hs.widget.open()
      return true
    } else {
      return false
    }
  }
}
