
import JigTextField from '@/components/input/JigTextField.vue'
import PromptYesNo from '@/components/modals/PromptYesNo.vue'
import GenericError from '@/components/snackbars/GenericError.vue'
import SubscriptionTracking from '@/mixin/SubscriptionTracking'
import { arrayToCsvString } from '@/plugins/filters'
import { Permissions } from '@/security/permissions'
import { AppConst } from '@/store/modules/constants'
import {
  Tenant,
  TenantSubscription,
  TenantUser,
  TenantUserAddRequest,
  TenantUserRemoveRequest,
  TenantUserUpdateRequest,
  UserRoleDefinition,
} from '@/store/modules/app/types'
import { DynaconfConfig, Namespace, PermissionActions } from '@/store/types'
import { AppHelper } from '@/utils/app-helper'
import { HSHelpers } from '@/utils/hs-helpers'
import { ValidationRules } from '@/utils/input-validation'
import { ListHelpers } from '@/utils/list-helpers'
import { TenantHelpers } from '@/utils/tenant-helpers'
import { segmentEventTracking, segmentIdentityTracking } from '@/utils/tracking'
import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

const rolesListingMode = {
  ADDING_USER: 1,
  EDITING_USER: 2,
  LIST_USERS: 3,
}
Object.freeze(rolesListingMode)

@Component({
  components: {
    'generic-error': GenericError,
    'prompt-yes-no': PromptYesNo,
    'jig-text-field': JigTextField,
  },
})

export default class TenantUsersVue extends Mixins(SubscriptionTracking) {
  @State('loadingModal', { namespace: Namespace.Utils })
  public loadingModal!: boolean
  @State('loading', { namespace: Namespace.Utils })
  public loading!: boolean
  @State('apiError', { namespace: Namespace.Utils })
  public apiError!: string
  @State('tenantUsers', { namespace: Namespace.App })
  public tenantUsers?: TenantUser[]
  @State('myTenantIndex', { namespace: Namespace.App })
  public myTenantIndex!: number
  @State('myTenants', { namespace: Namespace.App })
  public myTenants!: Tenant[]
  @State('tenantUserRoles', { namespace: Namespace.App })
  public tenantUserRoles!: UserRoleDefinition[]
  @State('tenantUserRoleDisplayNames', { namespace: Namespace.App })
  public tenantUserRoleDisplayNames!: string[]
  @State('tenantUsersItemsPerPage', { namespace: Namespace.Utils })
  public tenantUsersItemsPerPage!: number
  @State('dynaconfConfig', { namespace: Namespace.App })
  public dynaconfConfig!: DynaconfConfig

  @Action('loadTenantUserRoles', { namespace: Namespace.App })
  public loadTenantUserRoles: any
  @Action('loadMyTenantUsers', { namespace: Namespace.App })
  public loadMyTenantUsers: any
  @Action('addTenantUser', { namespace: Namespace.App })
  public addTenantUser: any
  @Action('updateTenantUser', { namespace: Namespace.App })
  public updateTenantUser: any
  @Action('removeTenantUser', { namespace: Namespace.App })
  public removeTenantUser: any

  public $refs!: Vue['$refs'] & {
    genericError: GenericError,
    removeUserPromptYesNo: PromptYesNo,
    userLimitPromptYesNo: PromptYesNo,
    upgradeSucceedPromptYesNo: PromptYesNo,
  }

  public emailRules !: any
  public roleRules !: any
  private editedUser!: TenantUser
  private previousUserRoleDisplayName: string = ''
  private dialogVisible: boolean = false
  private dialogValid: boolean = false
  private dialogEditUserMode: boolean = false
  private userRoleDisplayName: string = ''
  private editableUserIsManager: boolean = false
  private editableUserIsBillingContact: boolean = false
  private tableColumns: any[] = []
  private isSuperUser: boolean = false
  private rolesListingModes: any = rolesListingMode
  private tenantUserManager: boolean = false
  private canAddBillableUserRoles: boolean = false
  private openHSChatFailed: boolean = false
  private PresenterRoleDisplayName: string = 'Presenter'
  private subscription: TenantSubscription = {
    AdditionalSeats: 0,
    AllowCustomBranding: false,
    AllowMovoJigs: false,
    AllowJigStats: false,
    AllowPasswordJigs: false,
    AllowPrivateJigs: false,
    AllowWorkshopPropertyPanel: false,
    ManagedByType: '',
    MaxJigs: -1,
    MaxJigsCountPer: '',
    ModelUploadMaxSizeMB: -1,
    OverLimitWarningDismissableUntil: '',
    OverLimitWarningShowContactUs: false,
    PricingSet: AppConst.Subscription.pricingSet_2023_06,
    SeatsIncludedInTier: -1,
    StripeCustomerId: '',
    StripeSubscriptionId: '',
    SubscriptionFeatures: {
      avp: { enabled: false },
      movo: { enabled: false },
      stats: {
        jigStats: { enabled: false }
      },
    },
    TeamStorageLimitMB: -1,
    TierType: '',
    TierTypeDisplayName: '',
    ViewJigStepMax: -1,
    RestrictedJigCopyableByTypes: [],
  }
  private tenantUserHeaders: any[] = [
    {
      text: 'User ID',
      value: 'Uid',
      sortable: false,
    },
    {
      text: 'User E-mail',
      value: 'email',
      sortable: true,
    },
    {
      text: 'Role',
      value: 'roles',
      sortable: true,
    },
    {
      text: 'Extra Permissions',
      value: 'extraPermissionNames',
      sortable: false,
    },
    {
      text: '',
      value: 'actions',
      sortable: false,
    },
  ]

  public static buildPermissionsQueryParam(isManager: boolean, isBillingContact: boolean): string {
    let result = ''
    if (!isManager && !isBillingContact) {
      return result
    }

    let actionsAddedCount = 0
    result += Permissions.PermTenant.Name + ':'
    if (isManager) {
      result += PermissionActions.Manage
      actionsAddedCount++
    }
    if (isBillingContact) {
      if (actionsAddedCount > 0) {
        result += '|'
      }
      result += PermissionActions.BillingContact
      actionsAddedCount++
    }
    return result
  }

  constructor() {
    super()
    this.editedUser = {
      ID: -1,
      Uid: 0,
      dateCreated: new Date(),
      email: '',
      name: '',
      roles: [],
      status: '',
      tenantId: TenantHelpers.InvalidTenantID,
      username: '',
      permissions: {},
      extraPermissionNames: [],
    }
  }

  protected async created() {
    this.roleRules = ValidationRules.RequiredName
    this.emailRules = ValidationRules.RequiredEmail
    this.isSuperUser = TenantHelpers.IsSuperUser(this.$auth0.tenants)

    if (this.isSuperUser) {
      this.tableColumns = this.tenantUserHeaders
    } else {
      for (const h of this.tenantUserHeaders) {
        if (h.text !== 'User ID') {
          this.tableColumns.push(h)
        }
      }
    }
  }

  protected async mounted() {
    if (this.$route.query.checkoutresult === 'success') {
      await this.subscriptionEventTracking({
        gtmEventData: {
          action: 'CheckoutSuccess',
          category: 'CheckoutSelect',
          label: 'CheckoutSuccess',
        },
        segmentEventName: 'CheckoutSuccess'
      })

      this.$refs.upgradeSucceedPromptYesNo.ShowPrompt()
    }
    this.loadData()
  }

  @Watch('myTenantIndex')
  private onMyTenantIndexPropertyChanged(value: number, oldValue: number) {
    this.loadData()
  }

  @Watch('tenantUserCount')
  private async onTenantUserCountUpdated(value: number) {
    if (value !== TenantHelpers.InvalidTenantUserCount) {
      await segmentIdentityTracking(this.myTenants, this.myTenant, { TenantUserCount: value })
    }
  }

  private async loadData() {
    if (this.myTenantIndex !== TenantHelpers.InvalidTenantIndex) {
      if (await this.loadMyTenantUsers({})) {
        await this.loadTenantUserRoles({})
      } else {
        // If we get an error, it can be misleading if the tenantUsers are being shown from a previously selected tenant so we reset them here.
        this.$store.state.app.tenantUsers = []
      }
    }
    this.setPermissionsFromTenantInToken()
    if (this.userRoleDisplayName === '' && this.tenantUserRoleDisplayNames.length > 0) {
      this.userRoleDisplayName = this.tenantUserRoleDisplayNames[0]
    }
    this.openHSChatFailed = false
    this.subscription = this.myTenant.Subscription
  }

  private getRoleDisplayNamesForUserAndAction(roleMode: number, tenantUser?: TenantUser): string[] {
    let roles = []
    const roleDefs = this.getRoleDefsForUserAndAction(roleMode, tenantUser)
    for (const r of roleDefs) {
      roles.push(r.DisplayName)
    }
    return roles
  }

  private getRoleDefsForUserAndAction(roleMode: number, tenantUser?: TenantUser): UserRoleDefinition[] {
    let isStaff = false
    let guestViewerRoleAllowed = roleMode === rolesListingMode.LIST_USERS
    if (tenantUser !== undefined) {
      isStaff = this.isJigSpaceStaff(tenantUser)
      // Don't allow adding guest user - this is done through a jig invite.
      // When editing a user, if their role was originally a guest viewer, we allow them to still be a guest viewer.
      // But we disallow users downgrading to a guest viewer from a paid user.
      if (roleMode === rolesListingMode.EDITING_USER && tenantUser.roles.length > 0 && tenantUser.roles[0] === 'role_guestviewer1') {
        guestViewerRoleAllowed = true
      }
    }

    let roles = []
    for (const r of this.tenantUserRoles) {
      if (r.DisplayName === "Guest Viewer" && !guestViewerRoleAllowed) {
        continue
      }

      // When listing users we only want to display the roles the user has
      if (tenantUser !== undefined && roleMode === rolesListingMode.LIST_USERS) {
        if (tenantUser.roles && tenantUser.roles.indexOf(r.Name) === -1) {
          continue
        }
      }

      if (!isStaff && r.DisplayName !== "Guest Viewer" && r.RestrictToTenantIds.indexOf(TenantHelpers.GetJigSpaceStaffTenantID()) !== -1) {
        continue
      }

      roles.push(r)
    }
    return roles
  }

  private get tenantName(): string {
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant === undefined) {
      return ''
    }
    return tenant.Name
  }

  private get pageTitle(): string {
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant === undefined) {
      return 'Users'
    }
    return tenant.Name + ' users'
  }

  private get formTitle(): string {
    if (this.dialogEditUserMode) {
      return 'Edit User'
    } else {
      return 'Add User'
    }
  }

  private get rolesDifferent(): boolean {
    return this.previousUserRoleDisplayName !== this.userRoleDisplayName
  }

  get additionalSeatsQty(): number {
    return this.subscription.AdditionalSeats || 0
  }

  get isUserLimitReached(): boolean | undefined {
    return this.dynaconfConfig.pricing.sets[this.subscription.PricingSet] != null && this.tenantUsers && this.userLimitCount === this.tenantUserCount
  }

  get userLimitCount(): any {
    const seatsLimit = this.subscription.SeatsIncludedInTier === AppConst.Subscription.defaultSeatsIncludedInTier ? '-' : this.subscription.SeatsIncludedInTier as number + this.additionalSeatsQty

    return this.subscription ? seatsLimit : ''
  }

  get openJigSpace() {
    return AppHelper.openJigSpaceUrl()
  }

  public upgradePlan() {
    segmentEventTracking('TenantUserLimitTeamPanel_UpgradePanelClicked', {
      tenantName: this.tenantName,
      tenantId: this.editedUser.tenantId,
    })
    this.$router.push({
      path: '/subscription',
      query: {
        stripeSuccess: this.$route.path,
      },
    })
  }

  private resortTable() {
    if (this.tenantUsers !== null && this.tenantUsers !== undefined) {
      this.tenantUsers.push({} as TenantUser)
      this.tenantUsers.pop()
    }
  }

  private isJigSpaceStaff(tenantUser: TenantUser): boolean {
    return tenantUser.tenantId === TenantHelpers.GetJigSpaceStaffTenantID()
  }

  public openAddUserDialog() {
    this.dialogEditUserMode = false

    let tenantId = TenantHelpers.InvalidTenantID
    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant !== undefined) {
      tenantId = tenant.ID
    }

    this.editedUser = {
      ID: ListHelpers.calculateNextID(this.tenantUsers, 'ID'),
      Uid: 0,
      dateCreated: new Date(),
      email: '',
      name: '',
      roles: [],
      status: 'active',
      tenantId,
      username: '',
      permissions: {},
      extraPermissionNames: [],
    }

    this.editableUserIsManager = false
    this.editableUserIsBillingContact = false

    this.dialogVisible = true
  }

  private openHSChat() {
    this.openHSChatFailed = !HSHelpers.OpenHSChat()
  }

  private openUpdateUserDialog(user: TenantUser) {
    this.dialogEditUserMode = true
    this.editedUser = Object.assign({}, user)
    if (this.editedUser.roles.length > 0) {
      const role = this.editedUser.roles[0]
      this.userRoleDisplayName = this.getRoleDisplayName(role)
      this.previousUserRoleDisplayName = this.userRoleDisplayName
    } else {
      this.userRoleDisplayName = ''
      this.previousUserRoleDisplayName = ''
    }

    this.editableUserIsManager = this.editedUser.extraPermissionNames.indexOf(
      TenantHelpers.ExtraPermissionManager) !== -1
    this.editableUserIsBillingContact = this.editedUser.extraPermissionNames.indexOf(
      TenantHelpers.ExtraPermissionBillingContact) !== -1

    this.dialogVisible = true
  }

  private async confirmDialog() {
    if (this.dialogEditUserMode) {
      this.updateUser()
    } else {
      // Add User
      if (this.isUserLimitReached) {
        this.$refs.userLimitPromptYesNo.ShowPrompt()
        segmentEventTracking('TenantUserLimitTeamPanel_FeatureGateShown', {
          tenantName: this.tenantName,
          tenantId: this.editedUser.tenantId,
        })
        return
      }

      this.addUser()
    }
  }

  private cancelDialog() {
    this.dialogVisible = false
  }

  private getRoleDisplayName(roleName: string): string {
    const role = ListHelpers.getItemById(this.tenantUserRoles, 'Name', roleName)
    if (role !== null) {
      return role.DisplayName
    }

    return ''
  }

  private getUserRoleName(roleDisplayName: string): string {
    const roleData = ListHelpers.getItemById(this.tenantUserRoles, 'DisplayName', roleDisplayName)
    if (roleData !== null) {
      return (roleData as UserRoleDefinition).Name
    }
    return ''
  }

  private async addUser() {
    const roleName = this.getUserRoleName(this.userRoleDisplayName)
    const payload = {
      TenantId: this.editedUser.tenantId,
      UrlParams: {
        extra_perms: TenantUsersVue.buildPermissionsQueryParam(
          this.editableUserIsManager, this.editableUserIsBillingContact),
        email: this.editedUser.email,
        role: roleName,
        target_app: 'jigspace',
      },
    } as TenantUserAddRequest
    await this.addTenantUser(payload)

    if (this.apiError !== '') {
      return
    }

    this.dialogVisible = false
    await this.loadMyTenantUsers({})

    // Move tracking after new tenant users load to get the latest user count.
    segmentEventTracking('TenantUserCount', {
      user: this.editedUser.email,
      role: roleName,
      extraPermissions: payload.UrlParams['extra_perms'],
      tenantName: this.tenantName,
      tenantId: this.editedUser.tenantId,
      tenantUserCount: this.tenantUserCount,
    })
  }

  private async updateUser() {
    const roleName = this.getUserRoleName(this.userRoleDisplayName)
    const payload = {
      TenantId: this.editedUser.tenantId,
      UrlParams: {
        extra_perms: TenantUsersVue.buildPermissionsQueryParam(
          this.editableUserIsManager, this.editableUserIsBillingContact),
        Uid: this.editedUser.Uid,
        role: roleName,
      },
    } as TenantUserUpdateRequest
    await this.updateTenantUser(payload)

    if (this.apiError !== '') {
      return
    }

    segmentEventTracking('UpdateUser', {
      jigUserId: this.editedUser.Uid,
      user: this.editedUser.email,
      role: roleName,
      extraPermissions: payload.UrlParams['extra_perms'],
    })

    this.dialogVisible = false
    this.loadMyTenantUsers({})
  }

  private openRemoveUserPrompt(user: TenantUser) {
    if (user !== null && user !== undefined) {
      this.editedUser = user
      this.$refs.removeUserPromptYesNo.ShowPrompt()
    } else {
      throw new Error('user is null')
    }
  }

  public async removeUser() {
    const payload = {
      Uid: this.editedUser.Uid,
      TenantId: this.editedUser.tenantId,
    } as TenantUserRemoveRequest
    await this.removeTenantUser(payload)

    if (this.apiError !== '') {
      return
    }

    segmentEventTracking('DeleteUser', {
      jigUserId: this.editedUser.Uid,
      user: this.editedUser.email,
      tenantId: this.editedUser.tenantId,
    })

    this.dialogVisible = false
    this.loadMyTenantUsers({})
  }

  private setPermissionsFromTenantInToken() {
    if (this.isSuperUser) {
      this.tenantUserManager = true
      this.canAddBillableUserRoles = true
      return
    }
    const tenantTokens = this.$auth0.tenants

    const tenant = TenantHelpers.GetTenantByIndex(this.myTenantIndex, this.myTenants)
    if (tenant === undefined || tenant === null) {
      this.tenantUserManager = false
      this.canAddBillableUserRoles = false
      return false
    }

    const token = TenantHelpers.GetTenantTokenByTenantID(tenantTokens, tenant.ID)
    this.tenantUserManager = (token !== undefined &&
      Permissions.TokenHasPermissionAction(token, Permissions.PermTenant, PermissionActions.Manage))

    // Billing sources such as apple and android are not able have price or products changed off the device.
    this.canAddBillableUserRoles = (token !== undefined && TenantHelpers.CanAddBillableUsers(token))
  }

  private IsUserInTenantJigSpaceStaff(): boolean {
    return TenantHelpers.HasJigSpaceStaffToken(this.$auth0.tenants)
  }

  private canDeleteUser(user: TenantUser): boolean {
    if (this.isSuperUser) {
      return true
    }

    if (this.$auth0.uid !== user.Uid) {
      return this.tenantUserManager
    }

    return false
  }

  private itemsPerPageChange(newItemsPerPage: number) {
    // This is a 2 way binding
    this.$store.state.utils.tenantUsersItemsPerPage = newItemsPerPage
  }
}
